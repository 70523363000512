.App {
  text-align: center;
  min-height: 100vh;
  background-size: cover;  
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #1D2737;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.dynamic-button {
  font-size: 20px; /* Adjust to the size you want */
  transition: all 0.5s;
}

.dynamic-button:hover {
  transform: scale(1.1);
}

.monster-title {
  display: inline-block;
  padding: 10px;
  font-size: 24px; /* Adjust to the size you want */
  border: 2px solid #ffad06;
  background-color: rgba(0, 0, 0, 0.5); /* Semi-transparent black */
  color: white;
  border-radius: 10px;
  box-shadow: 0px 8px 15px rgba(0, 0, 0, 0.1);
}

.timer {
  text-align: center;
  font-weight: bold;
  margin-bottom: 40px; /* Adjust as needed */
  font-size: 22px; /* Adjust to the size you want */
  border: 3px solid #ffad06;
  background-color: rgba(0, 0, 0, 0.5); /* Semi-transparent black */
  color: white;
  border-radius: 14px;
  box-shadow: 0px 10px 15px rgba(0, 0, 0, 0.1);
  padding: 20px; /* Adjust to the size you want */
}
.navbar {
  font-size: 18px; /* Adjust to the size you want */
  box-shadow: 0px 8px 15px rgba(0, 0, 0, 0.1);
}

.navbar a, .navbar button {
  transition: all 0.5s;
}

.navbar a:hover, .navbar button:hover {
  transform: scale(1.1);
}
