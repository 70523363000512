@tailwind base;
@tailwind components;
@tailwind utilities;
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #1E2634;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

@font-face {
  font-family: 'PixelFont';
  src: url('/font/Quantum.otf') format('truetype');
}

@font-face {
  font-family: 'BoldFont';
  src: url('/font/Quantum.otf') format('truetype');
}


.font-pixel {
  font-family: 'PixelFont', sans-serif;
}

.font-bold {
  font-family: 'BoldFont', sans-serif;
}
